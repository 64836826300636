import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Sharna = () => (
  <Layout>
    <SEO
      title="Learning to Love Natural Hair with Sharna"
      description="Sharna tells us about her journey and learning to love & appreciate her natural hair. She talks about growing up mixed race and learning about herself."
    />
    <h1>Learning to Love Natural Hair with Sharna</h1>
    <h2 style={{color:"Orchid"}}>Sharna tells us about her journey and learning to love & appreciate her natural hair. She talks about growing up mixed race and learning about herself</h2>


    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/kUPJaRW.jpg"
        alt="sharna selfie curly natural hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Sharna Salmon</p>
    </div>

    <h3>Hello Sharna, tell us a bit about yourself</h3>
    <p>My name is Sharna-Louise Salmon, I’m 22. I’m a professional nap taker who loves pizza and Netflix. I'm also an Administrator from Nottingham.</p>

    <h3>What does your natural hair mean to you?</h3>
    <p>My natural hair is very important to me, I’ve only really appreciated my natural  hair the last two years, and am so happy I can rock my fro now with confidence. My hair makes me feel special.</p>

    <h3>How would you describe your hair?</h3>
    <p>Tight ringlets/ waves</p>

    <h3>What is your family history?</h3>
    <p>Mum British Jamaican dad white British</p>

    <h3>Talk us through your hair routine</h3>
    <p>Depending on my mood I will wash my hair one a week or once every two weeks, when my hair is freshly washed I apply castor oil, leave in conditioner, curl activator and various ingredients if I have the time. I let my hair air dry as this gives it better curl and structure. For the rest of the week I just wet it with water and sometimes apply curl gel.</p>

    <h3>How did you view your hair growing up and were other people interested in your hair?</h3>
    <p>I didn’t really like my natural hair between the ages of 9 to 17, I felt like I wouldn’t be accepted with my natural hair and felt un pretty so I would straighten it every day (damaging it) most people would say it was lovely and curly but I really didn’t like it. I wanted pin straight hair like most people I would see either on tv or on the street.</p>

    <h3>Did you have a role model growing up that helped you and how did you learn to take care of your hair?</h3>
    <p>I mainly took care of my own hair, I saw my sister straighten hers so this may have had some influence in me doing the same, but I never really saw anyone else with my hair type at all. I was the only mixed race person in my year for most of it and my class mates of colour would either have their hair relaxed or in brads.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/rsUDfJx.jpg"
        alt="sharna throwback young picture"
        style={{marginBottom: "5px"}}
      />
    </div>

    <h3>Any resources you use now?</h3>
    <p>I use Instagram a lot for looking at ideas for my hair also go to YouTube but not often.</p>

    <h3>What happened when you started doing your hair yourself?</h3>
    <p>My hair is kind of easy to deal with so didn’t take me long.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/J4agCRu.jpg"
        alt="sharna last night styled hair"
        style={{marginBottom: "5px"}}
      />
    </div>

    <h3>Any interesting/ funny stories?</h3>
    <p>When wanting a certain hair style I forced myself to stay up most of the night to do it, after finishing the style then had to rap my head which did not go well as I find it very difficult. I had no sleep as the style hurt so bad and when I finally got to take it out the next morning, it was still wet and did not come our correctly ( I have done the same thing multiple times and it is not worth the stress so don’t do this anymore).</p>

    <h3>Anything you wish you knew sooner?</h3>
    <p>I wish I knew how bad straightening my hair is.</p>

    <h3>What advice would you give for taking care of natural hair?</h3>
    <p>Even though I don’t do this and I really should, I think making sure to cut off all the dead end will help your hair grow and stay healthy ( I have done a short cycle of this and it defiantly made a difference with how my hair looked).</p>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>Having it out as normal in a nice little fro.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/HwjCEe6.jpg"
        alt="sharna salmon mirror curly hair"
        style={{marginBottom: "5px"}}
      />
    </div>

    <h3>Do you have any hair plans for the future?</h3>
    <p>I would like to cut it really short, like 5 inches but I need to boost up the self-confidence before that happens.</p>

    <h3>What made you change your mind about your hair choices?</h3>
    <p>I chose to go natural because my hair was dead from all the heat damage, it was so thin I hated it. The transition was hard because I felt like my curls were ugly but I pushed myself to deal with it to get stronger hair. After a while I would go on social media and see more people with curly hair and theirs was beautiful. It really opened my mind and showed me that my hair was pretty and now I love it. I do however have it straight every now and then just for a change (I shouldn’t really through).</p>

    <h3>What are the different comments that have been made about your hair?</h3>
    <p>I get different comets a lot about my hair, mostly positive but every now and then someone would say you would look better with straight hair. That comment is annoying as it makes me feel like my curls are not accepted.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/zFw1QDC.jpg"
        alt="sharna self curly pout"
        style={{marginBottom: "5px"}}
      />
    </div>

    <h3>What are you passionate about?</h3>
    <p>I love seeing more people going natural and rocking their curls/ fro I think its overly beautiful and amazing.</p>

    <h3>How can we follow/ contact you?</h3>
    <p>We're family but insta - <a
        href="https://www.instagram.com/sharnalouise99/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @sharnalouise99
      </a></p>




    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}





      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/natural-hair-with-manna/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Model, Artist and Musician - Natural Hair with Manna McLeod
            </Link>{" "}
            - We talk natural hair with the beautiful model Manna. We picked up some tips and learn about some new products. Can’t wait for you to read!...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Sharna
